<template>
    <div>
        <div class="mb-6 mt-6 flex w-full">
            <div v-if="isMounted" class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto">
                <vx-card class="mb-16 mt-10" v-if="!surveyAnswered">
                    <h3 class="orange mb-5">¡En la Red te escuchamos!</h3>
                    <vs-table stripe :data="questions" noDataText="Sin preguntas">
                        <template slot="header">
                            <h3 class="bold">Cuéntanos sobre tu experiencia con el proveedor.</h3>
                        </template>
                        <template slot="thead">
                            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                                <template v-if="tr.type == 'SP_QUESTION' && tr.data_type == 'combo'">
                                    <vs-td>
                                        <p class="bold"> {{ tr.question }}</p>
                                        <p>{{ tr.indications }}</p>
                                    </vs-td>
                                    <vs-td v-for="(answ, indexAnsw) in tr.responses" :key="indexAnsw">
                                        <template v-if="!isYesOrNotQuestion(tr)">
                                            <label class="vs-component con-vs-radio vs-radio-primary">
                                                <input 
                                                    type="radio" 
                                                    class="vs-radio--input"
                                                    v-model="answers[indextr]"
                                                    :name="`a_${tr.id}`" 
                                                    :value="answ.value"  
                                                    >
                                                <span class="vs-radio"><span class="vs-radio--borde" style="border: 2px solid rgb(200, 200, 200);"></span><span class="vs-radio--circle"></span></span>
                                            </label>
                                        </template>
                                        <template v-else>
                                            <label v-if="answ.value == 1 || answ.value == 5" class="vs-component con-vs-radio vs-radio-primary">
                                                <input 
                                                    type="radio" 
                                                    class="vs-radio--input"
                                                    v-model="answers[indextr]"
                                                    :name="`a_${tr.id}`" 
                                                    :value="answ.value"  
                                                    >
                                                <span class="vs-radio"><span class="vs-radio--borde" style="border: 2px solid rgb(200, 200, 200);"></span><span class="vs-radio--circle"></span></span>
                                                <span class="bold ml-1"> {{ answ.value == 1 ? "No" : "SI" }}</span>
                                            </label>
                                        </template>
                                    </vs-td>
                                </template>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <div class="vx-col bold" style="padding: .5rem 1rem .5rem 1rem;">
                        {{questions[getTextQuestionIndedx(true)].question}}
                        <vs-textarea maxlength="255" v-model="answers[getTextQuestionIndedx(true)]"/>
                    </div>
                   

                    <vs-table stripe :data="questions" noDataText="Sin preguntas">
                        <template slot="header">
                            <h3 class="bold">Cuéntanos sobre tu experiencia con RedGirasol.</h3>
                        </template>
                        <template slot="thead">
                            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                                <template v-if="tr.type == 'RG_QUESTION' && tr.data_type == 'combo'">
                                    <vs-td>
                                        <p class="bold"> {{ tr.question }}</p>
                                        <p>{{ tr.indications }}</p>
                                    </vs-td>
                                    <vs-td v-for="(answ, indexAnsw) in tr.responses" :key="indexAnsw">
                                        <label class="vs-component con-vs-radio vs-radio-primary">
                                            <input 
                                                type="radio" 
                                                class="vs-radio--input"
                                                v-model="answers[indextr]"
                                                :name="`a_${tr.id}`" 
                                                :value="answ.value" 
                                                > 
                                            <span class="vs-radio"><span class="vs-radio--borde" style="border: 2px solid rgb(200, 200, 200);"></span><span class="vs-radio--circle"></span></span>
                                        </label>
                                    </vs-td>
                                </template>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <div class="vx-col bold" style="padding: .5rem 1rem .5rem 1rem;">
                        {{questions[getTextQuestionIndedx(false)].question}}
                        <vs-textarea maxlength="255" v-model="answers[getTextQuestionIndedx(false)]"/>
                    </div>

                    <vs-button class="mb-6" style="float: right;" @click="validateAnswers()">Enviar</vs-button>
                </vx-card>
                
                <vx-card style="padding: 4.5rem;" class="mt-16 mb-20 text-center w-full" v-else>
                    <img src="@/assets/images/figs/fig9.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">        
                    <h2 class="mb-6 mt-6">Gracias por compartirnos tu experiencia,
                                    <br>juntos hacemos que nuestra Red
                                    <br>sea la más brillante.</h2>
                    <h2 class="orange">¡Hasta pronto!</h2>
                </vx-card>

            </div>

        </div>
    </div>
</template>

<script>
const columnDefinitions = [
    {title: "Pregunta", key: "question", sortable: false }
    , {title: "1", key: "question_1", sortable: false }
    , {title: "2", key: "question_2", sortable: false }
    , {title: "3", key: "question_3", sortable: false }
    , {title: "4", key: "question_4", sortable: false }
    , {title: "5", key: "question_5", sortable: false }

]

export default {
    name: 'applicant-survey-feedback',
    data() {
        return {
            questions: []
            , columns: columnDefinitions
            , answers: []
            , isMounted: false
            , clientId: 0
            , projectId: 0
            , surveyAnswered: false
        }
    },
    async created(){
        await this.validatePage();
        this.getApplicantsSurveyFeedback();
    },
    methods: {
        async getApplicantsSurveyFeedback() {
            this.showLoading(true);
            let response = await this.publicApiGet(`/api/v1/applicant_satisfaction_survey/getApplicantsSurveyFeedback`);
            this.questions = response.data;
            this.isMounted = true;
            this.showLoading(false);
        },

        validateAnswers() {
            let questionsLength = this.questions.length;
            let isFull = false;

            for (let i = 0; i <questionsLength; i++) {
                if(typeof this.answers[i] === 'undefined'){
                    isFull = false;
                    break;
                }
                isFull = true;
            }

            if(isFull){
                if(this.clientId != 0 && this.projectId != 0){
                    this.saveSurveyAnswers();
                } else {
                    this.failedOperationNotif();
                }
            } else {
                this.missingFieldsNotif("Preguntas faltantes", "Por favor responda todas las preguntas desplegadas.");
            }
            
        },

        saveSurveyAnswers() {
            let answers = [];
            let counter = 0;
            this.questions.forEach(question => {
                let value = this.answers[counter] != undefined ? this.answers[counter] : "Sin comentarios.";
                let answer = {
                    fg_survey_question_id: question.id
                    , value: value
                    , client_id: this.clientId
                    , project_id: this.projectId
                }

                answers.push(answer);
                counter++;
            });

            this.postSurveyAnswers(answers);
            
        },
        
        async postSurveyAnswers(answers) {
            this.showLoading(true);
            let payload = { applicant_survey_feedback_answers: answers };
            try {
                let response = await this.publicApiPost("api/v1/applicant_satisfaction_survey/saveApplicantsSurveyFeedback", payload);
                if(response.status == 200 && response.statusText == "OK"){
                    this.surveyAnswered = true;
                    this.saveSuccessNotif();
                }
            } catch(err) {
                this.failedOperationNotif();
            }
            this.showLoading(false);
        },

        async validatePage() {
            let url_string = window.location.href;
            let url = new URL(url_string);
            let cid = url.searchParams.get("cid");
            let pid = url.searchParams.get("pid");
            let token = url.searchParams.get("t");
            if(cid != null && pid != null && token != null){
                this.clientId = cid;
                this.projectId = pid;

                let payload = {
                    client_id: cid
                    , project_id: pid
                    , token: token
                }
                let response = await this.publicApiPost("api/v1/applicant_satisfaction_survey/validateApplicantSurver", payload);
                if(response.data) {
                    this.$router.push("notFound");
                }
            } else {
                this.$router.push("notFound");
            }
        },
        updateFooter (val) {
            this.footerType = val
        },
        currentYear(){
            return new Date().getFullYear();
        },
        getTextQuestionIndedx(isSupplier) {
            // Si pudieras mejorar algo de tu proveedor, ¿qué sería?
            // Si pudieras mejorar algo de RedGirasol, ¿qué sería?
            let text = "Si pudieras mejorar algo de RedGirasol, ¿qué sería?";
            if(isSupplier){
                text = "Si pudieras mejorar algo de tu proveedor, ¿qué sería?";
            }
            let index = this.questions.findIndex( x => x.question == text);
            if(index != -1){
                return index;
            } else {
                console.warn("Preguntas no definidas");
                return 99;
            }
        },
        isYesOrNotQuestion(question){
            const FIRST_QUESTION = '¿Fueron instalados los equipos acordados?';
            const SECOND_QUESTION = '¿El instalador realizó los trámites antes CFE para tu cambio de medidor?';
            const THIRD_QUESTION = '¿Cumplieron con lo que te había cotizado y prometido?';
            if(question.question == FIRST_QUESTION || question.question == SECOND_QUESTION || question.question == THIRD_QUESTION){
                return true;
            } 
            return false;
        }
    }
}
</script>